// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-business-analytics-js": () => import("./../../../src/pages/business-analytics.js" /* webpackChunkName: "component---src-pages-business-analytics-js" */),
  "component---src-pages-epic-cashback-program-js": () => import("./../../../src/pages/epic-cashback-program.js" /* webpackChunkName: "component---src-pages-epic-cashback-program-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-free-ongkir-program-js": () => import("./../../../src/pages/free-ongkir-program.js" /* webpackChunkName: "component---src-pages-free-ongkir-program-js" */),
  "component---src-pages-fulfillment-js": () => import("./../../../src/pages/fulfillment.js" /* webpackChunkName: "component---src-pages-fulfillment-js" */),
  "component---src-pages-help-center-js": () => import("./../../../src/pages/help-center.js" /* webpackChunkName: "component---src-pages-help-center-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integration-js": () => import("./../../../src/pages/integration.js" /* webpackChunkName: "component---src-pages-integration-js" */),
  "component---src-pages-inventory-management-js": () => import("./../../../src/pages/inventory-management.js" /* webpackChunkName: "component---src-pages-inventory-management-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-marketplace-integration-js": () => import("./../../../src/pages/marketplace-integration.js" /* webpackChunkName: "component---src-pages-marketplace-integration-js" */),
  "component---src-pages-mobile-js": () => import("./../../../src/pages/mobile.js" /* webpackChunkName: "component---src-pages-mobile-js" */),
  "component---src-pages-ngorder-mega-cashback-js": () => import("./../../../src/pages/ngorder-mega-cashback.js" /* webpackChunkName: "component---src-pages-ngorder-mega-cashback-js" */),
  "component---src-pages-order-management-js": () => import("./../../../src/pages/order-management.js" /* webpackChunkName: "component---src-pages-order-management-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-private-order-js": () => import("./../../../src/pages/private-order.js" /* webpackChunkName: "component---src-pages-private-order-js" */),
  "component---src-pages-seller-reward-program-js": () => import("./../../../src/pages/seller-reward-program.js" /* webpackChunkName: "component---src-pages-seller-reward-program-js" */),
  "component---src-pages-seller-story-js": () => import("./../../../src/pages/seller-story.js" /* webpackChunkName: "component---src-pages-seller-story-js" */),
  "component---src-pages-shipping-management-js": () => import("./../../../src/pages/shipping-management.js" /* webpackChunkName: "component---src-pages-shipping-management-js" */),
  "component---src-pages-smartchat-js": () => import("./../../../src/pages/smartchat.js" /* webpackChunkName: "component---src-pages-smartchat-js" */),
  "component---src-pages-storefront-js": () => import("./../../../src/pages/storefront.js" /* webpackChunkName: "component---src-pages-storefront-js" */),
  "component---src-pages-terms-for-shipping-js": () => import("./../../../src/pages/terms-for-shipping.js" /* webpackChunkName: "component---src-pages-terms-for-shipping-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-update-detail-js": () => import("./../../../src/pages/update-detail.js" /* webpackChunkName: "component---src-pages-update-detail-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-video-template-js": () => import("./../../../src/templates/video-template.js" /* webpackChunkName: "component---src-templates-video-template-js" */)
}

